<template>
  <v-container>
    
    <v-tabs
      class="ma-4x"
      v-model="tab"
      background-color="white"
      color="primary"
      dark
    >
      <v-tab class="text--secondary">Nuevo Usuario</v-tab>
      <v-tab class="text--secondary">Directores</v-tab>
      <v-tab class="text--secondary">Gerentes</v-tab>
    </v-tabs>
    <!-- <div v-for="user in allManagers" :key="user.username">
      <AbstractUserProfile :isAdmin="true" :username="user.username" :userType="user.userType" />
    </div> -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <AbstractUserCard
          class="mt-5"
          :isUserInfo="false"
          :isDirector="false"
          :establishmentsDataTableHeaders="establishmentsDataTableHeaders"
          :establishments="establishments"
          @createNewManagerUser="createNewManagerUser"
          @alert="alertNotification"
          @createNewDirectorUser="createNewDirectorUser"
          @refreshUsers="refreshUsers"
        />
      </v-tab-item>
      <v-tab-item>
        <p
          v-if="isDirectorsEmpty"
          class="button text-center text--disabled pa-5 mt-5  text--lighten-5"
        >
          Todavia no hay usuarios directores
        </p>
        <AbstractUserCard
          v-else
          class="mt-5"
          v-for="director in allDirectors"
          :user="director"
          :key="director.id"
          :userId="director.id"
          :usernameInfo="director.username"
          :isDirector="true"
          :isUserInfo="true"
          @saveNewUsername="saveNewUsername"
          @refreshUsers="refreshUsers"
          @alert="alertNotification"
          @deleteUser="deleteUser"
          @saveNewPassword="saveNewPassword"

        />
      </v-tab-item>
      <v-tab-item>
        <p
          v-if="isManagersEmpty"
          class="button text-center text--disabled pa-5 mt-5 text--lighten-5"
        >
          Todavia no hay usuarios gerentes
        </p>
        <AbstractUserCard
          v-else
          class="mt-5"
          v-for="manager in parsedManagers"
          :key="manager.id"
          :isDirector="false"
          :userId="manager.id"
          :user="manager"
          :usernameInfo="manager.username"
          :userEstablishments="manager.establishments"
          :isUserInfo="true"
          :establishmentsDataTableHeaders="establishmentsDataTableHeaders"
          :establishments="establishments"
          @saveNewUsername="saveNewUsername"
          @removeReleatedEstablishments="removeReleatedEstablishments"
          @addEstablishmentsRelations="addEstablishmentsRelations"
          @refreshUsers="refreshUsers"
          @alert="alertNotification"
          @deleteUser="deleteUser"
          @saveNewPassword="saveNewPassword"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AbstractUserCard from "../components/Abstract/Users/AbstractUserCard.vue";
import {
  deleteManagerEstablishmentRelations,
  deleteDirectorUser,
  deleteManagerUser,
  postManagerRelations,
  postDirectorUser,
  postManagerUser,
  putDirectorUser,
  putManagerUser,
  putUserPassword,
} from "../managrx/AutAndAuthServices";

export default {
  name: "AdminPanel",
  components: { AbstractUserCard },
  mounted() {
    this.$store.dispatch("getAllUsers");
    this.$store.dispatch("getEstablishments");
  },
  computed: {
    ...mapGetters(["allManagers", "allDirectors", "establishments"]),
    parsedManagers() {
      if (this.allManagers) {
        return this.allManagers.map((el) => {
          return {
            ...el,
            establishments: this.establishments.filter((item) =>
              el.establishmentsIds.includes(item.id)
            ),
          };
        });
      } else {
        return {};
      }
    },
    isManagersEmpty() {
      return this.allManagers?.length ? false : true;
    },
    isDirectorsEmpty() {
      return this.allDirectors?.length ? false : true;
    },
    engagementDataTableHeaders() {
      return [
        { name: "Campaña", value: "campaignName" },
        { name: "Version", value: "campaignVersion" },
        { name: "Establecimiento", value: "establishmentName" },
      ].map((i) => {
        return {
          text: i.name.toLocaleUpperCase(),
          value: i.value,
        };
      });
    },
  },
  methods: {
    async saveNewPassword(payload){
      this.$store.dispatch("setPageLoadingStatus", true);
      let result = await putUserPassword(payload.userId, payload.password);
      if (!result.success) {
        this.$store.dispatch("setNewNotification", result);
      }
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    alertNotification(alert) {
      console.log("this is emit", alert);
      this.$store.dispatch("setNewNotification", alert);
    },
    async deleteUser(payload) {
      let isDirector = payload?.isDirector;
      let result;
      if (isDirector) {
        result = await deleteDirectorUser(payload.id);
      } else {
        result = await deleteManagerUser(payload.id);
      }
      if (result.success) {
        this.refreshUsers();
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
    async saveNewUsername(payload) {
      let isDirector = payload?.isDirector;
      let data = { id: payload?.user?.id, username: payload?.username };
      let result;
      if (isDirector) {
        result = await putDirectorUser(data);
      } else {
        result = await putManagerUser(data);
      }
      if (result.success) {
        this.refreshUsers();
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
    async removeReleatedEstablishments(payload) {
      let relationsIdsToRemove = payload.relationsIdsToRemove;

      let resultArray = await deleteManagerEstablishmentRelations(
        relationsIdsToRemove
      );

      this.checkResultArrayAndRefreshUsers(resultArray);
    },
    async addEstablishmentsRelations(payload) {
      let manager = payload.user;
      let establishmentsIds = payload.establishmentsIds;
      let resultArray = await postManagerRelations(
        manager?.id,
        establishmentsIds
      );
      this.checkResultArrayAndRefreshUsers(resultArray);
    },
    async createNewDirectorUser(userInfo) {
      let result = await postDirectorUser(userInfo);
      if (result?.success) {
        this.refreshUsers();
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
    async createNewManagerUser(managerInfo) {
      let result = await postManagerUser(managerInfo.userInfo);

      if (result?.success) {
        let resultArray = await postManagerRelations(
          result?.data?.id,
          managerInfo?.establishmentsIds
        );

        this.checkResultArrayAndRefreshUsers(resultArray);
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
    checkResultArrayAndRefreshUsers(resultArray) {
      if (!resultArray.success) {
        let data = resultArray.data;
        for (const result of data) {
          if (!result.success) {
            this.$store.dispatch("setNewNotification", result);
          }
        }
      } else {
        this.refreshUsers();
      }
    },
    refreshUsers() {
      /* Force update to rerender component, computed arrays can't re render */
      this.$store.dispatch("getAllUsers");
    },
  },
  data() {
    return {
      tab: null,
      establishmentsDataTableHeaders: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Hectáreas Físicas",
          value: "hectares",
        },
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Creado",
          value: "createdAt",
        },
      ],
    };
  },
};
</script>

<style></style>
