<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row align="center" justify="start" class="me-3">
          <!-- <v-icon class="mt-1 mr-2"
            >mdi-account-multiple-plus-outline</v-icon
          > -->
          <v-col :cols="colSize" class="d-flex justify-center align-center">
            <v-select
              item-text="name"
              item-value="value"
              v-model="userType"
              :items="[
                { name: 'Gerente', value: 'manager' },
                { name: 'Director', value: 'director' },
              ]"
              dense
              :readonly="isUserInfo"
              :filled="isUserInfo"
              hide-details
              outlined
            >
              <template slot="label">
                <v-icon small>mdi-account-hard-hat</v-icon> Tipo de usuario
              </template></v-select
            >
          </v-col>
          <v-col :cols="colSize"
            ><v-text-field
              hide-details
              outlined
              dense
              :readonly="isUserInfo && !edit"
              :filled="isUserInfo && !edit"
              v-model="username"
              ><template slot="label">
                <v-icon small>mdi-account-outline</v-icon> Nombre de usuario
              </template></v-text-field
            ></v-col
          >
          <v-col :cols="colSize" v-if="!isUserInfo"
            ><v-text-field hide-details outlined dense v-model="password"
              ><template slot="label">
                <v-icon small>mdi-lock-plus-outline</v-icon> Contraseña
              </template></v-text-field
            ></v-col
          >
          <v-col :cols="colSize" v-if="isUserInfo">
            <v-btn
              color="warning"
              elevation="0"
              v-if="!changeUserPassword"
              @click="changeUserPassword = true"
              >Cambiar Contraseña</v-btn
            >
            <div class="d-flex flex-row">
              <v-text-field
                v-if="changeUserPassword"
                hide-details
                outlined
                dense
                v-model="password"
                ><template slot="label">
                  <v-icon small>mdi-lock-plus-outline</v-icon> Nueva Contraseña
                </template></v-text-field
              >
              <v-btn
                color="primary"
                class="ms-2 white--text"
                elevation="0"
                v-if="changeUserPassword"
                @click="saveNewPassword"
                ><v-icon>mdi-floppy</v-icon></v-btn
              >
              <v-btn
                color="red"
                class="ms-2 white--text"
                elevation="0"
                v-if="changeUserPassword"
                @click="changeUserPassword = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </v-col>
          <v-col
            :cols="colSize"
            v-if="!directorSelected && (!isUserInfo || edit)"
          >
            <v-btn color="secondary" @click="openDialog">
              <v-icon small>mdi-factory </v-icon>
              <span class="ms-1">Asociar Establecimientos</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-subtitle v-if="!directorSelected" class="mt-0 mx-4">
        Establecimientos Asociados
        <v-divider></v-divider>
        <div>
          <p
            v-if="isReleatedEstablishmentsEmpty"
            class="button text-center text--disabled pa-5 text--lighten-5"
          >
            Todavia no hay establecimientos asociados
          </p>
          <v-chip
            v-else
            v-for="releatedEstablishment in releatedEstablishments"
            :key="releatedEstablishment.id"
            class="ma-2"
            :close="edit || !isUserInfo"
            @click="goToEstablishment(releatedEstablishment.id)"
            @click:close="removeReleatedEstablishment(releatedEstablishment.id)"
          >
            ( Id:{{ releatedEstablishment.id }})
            {{ releatedEstablishment.name }}
            {{ releatedEstablishment.regionName }},
            {{ releatedEstablishment.hectares }} Hectáreas
          </v-chip>
        </div>
      </v-card-subtitle>

      <v-card-actions class="pa-2">
        <v-row align="center" justify="end" class="ma-2 pa-2">
          <v-btn
            v-if="isUserInfo && !edit && deleteButton"
            :depressed="true"
            color="warning white--text"
            class="mr-2"
            @click="editClick"
          >
            <v-icon> mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            depressed
            color="error  white--text"
            class="mr-2"
            @click="deleteClick"
            v-if="isUserInfo && deleteButton"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <v-btn
            v-if="isUserInfo && confirmDelete"
            elevation="5"
            color="error"
            class="mr-2 white--text lighten-1"
            @click="confirmDeleteClick"
          >
            Confirmar borrado
          </v-btn>
          <v-btn
            v-if="isUserInfo && (confirmDelete || edit)"
            :depressed="true"
            color="warning white--text"
            class="mr-2"
            @click="backClick"
          >
            Atrás
          </v-btn>
          <v-btn
            color="primary white--text"
            @click="saveClick()"
            v-if="!isUserInfo || edit"
          >
            <v-icon> mdi-floppy </v-icon>
          </v-btn>
        </v-row></v-card-actions
      >
    </v-card>

    <v-dialog class="pa-4" v-model="dialog" scrollable max-width="900px">
      <v-card>
        <v-card-text style="height: 400px">
          <v-tabs
            class="my-2 pa-0"
            v-model="tab"
            background-color="white"
            color="primary"
            :centered="true"
            dark
          >
            <v-tab class="text--secondary">Establecimientos</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="mt-3">
                <v-data-table
                  v-if="!isEstablishmentsEmpty"
                  class="establishment-btn"
                  dense
                  :headers="establishmentsDataTableHeaders"
                  :items="establishments"
                  item-key="id"
                  show-select
                  v-model="establishmentsSelected"
                  :items-per-page="100"
                  hide-default-footer
                  ><template v-slot:[`header.data-table-select`]></template
                ></v-data-table>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeAndSaveDialog">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
export default {
  name: "AbstractUserCard",
  props: {
    userId: Number,
    user: Object,
    isDirector: Boolean,
    isUserInfo: Boolean,
    usernameInfo: String,
    userEstablishments: Array,
    establishmentsDataTableHeaders: Array,
    establishments: Array,
  },
  watch: {
    userEstablishments: function (newVal) {
      // watch prop for update, and force rerender
      this.releatedEstablishments = newVal;
    },
    usernameInfo: function (newVal) {
      // watch prop for update, and force rerender
      this.username = newVal;
    },
  },
  data() {
    return {
      changeUserPassword: false,
      userType: this.isDirector ? "director" : "manager",
      username: this.usernameInfo ? this.usernameInfo : null,
      password: null,
      dialog: false,
      confirmDelete: false,
      deleteButton: true,
      edit: false,
      releatedEstablishments: this.isUserInfo ? this.userEstablishments : [],
      establishmentsSelected: [],
      tab: null,
    };
  },
  methods: {
    saveNewPassword() {
      this.$emit("saveNewPassword", {
        userId: this.userId,
        password: this.password,
      });
      this.changeUserPassword = false;
      this.password = null;
    },
    goToEstablishment(id) {
      let routeData = this.$router.resolve({
        name: "SingleEstablishment",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },
    confirmDeleteClick() {
      this.confirmDelete = false;
      this.deleteButton = true;
      this.deleteUser();
    },
    deleteClick() {
      this.deleteButton = false;
      this.confirmDelete = true;
    },
    editClick() {
      this.edit = true;
      this.deleteButton = false;
      this.confirmDelete = false;
    },
    backClick() {
      this.confirmDelete = false;
      this.deleteButton = true;
      this.edit = false;
      this.setInitialState();
    },
    setInitialState() {
      this.userType = this.isDirector ? "director" : "manager";
      this.username = this.usernameInfo ? this.usernameInfo : null;
      this.password = null;
      this.dialog = false;
      this.confirmDelete = false;
      this.deleteButton = true;
      this.edit = false;
      this.releatedEstablishments = this.isUserInfo
        ? this.userEstablishments
        : [];
      this.establishmentsSelected = [];
    },
    alertBadForm() {
      let message = "Los datos introducidos no son validos";
      let payload = {
        success: false,
        data: {
          type: "alert",
          text: message,
        },
      };
      console.log("alert", payload);
      this.$emit("alert", payload);
    },
    saveClick() {
      if (!this.isUserInfo) {
        if (!this.badNewUser) {
          this.saveNewUser();
        } else {
          console.log("send alert on new user");
          this.alertBadForm();
        }
      } else {
        if (!this.badEditUser) {
          this.saveEdits();
        } else {
          console.log("send alert on edit user");

          this.alertBadForm();
        }
      }
    },
    saveEdits() {
      if (this.username != this.usernameInfo) {
        this.$emit("saveNewUsername", {
          user: this.user,
          isDirector: this.isDirector,
          username: this.username,
        });
      }
      if (!this.isDirector) {
        if (this.relationsToRemove?.length > 0) {
          this.$emit("removeReleatedEstablishments", {
            user: this.user,
            relationsIdsToRemove: this.relationsToRemove,
          });
        }
        if (this.newEstablishmentsRelations?.length > 0) {
          this.$emit("addEstablishmentsRelations", {
            user: this.user,
            establishmentsIds: this.newEstablishmentsRelations,
          });
          this.setInitialState();
        }
      }
      this.setInitialState();
    },
    saveNewUser() {
      if (this.userType == "manager") {
        let userPayload = {
          username: this.username,
          password: this.password,
          isDirector: false,
        };
        let releatedEstablishmentsIds = this.releatedEstablishments.map(
          (el) => el.id
        );
        let managerPayload = {
          userInfo: userPayload,
          establishmentsIds: releatedEstablishmentsIds,
        };
        this.$emit("createNewManagerUser", managerPayload);
      } else if (this.userType == "director") {
        let userPayload = {
          username: this.username,
          password: this.password,
          isDirector: true,
        };

        this.$emit("createNewDirectorUser", userPayload);
      }
      this.setInitialState();
    },
    deleteUser() {
      if (this.releatedEstablishments?.length > 0) {
        let message =
          "Para eliminar a este usuario deben removerse todos los negocios asociado";
        let payload = {
          success: false,
          data: {
            type: "alert",
            text: message,
          },
        };
        this.$emit("alert", payload);
      } else {
        this.$emit("deleteUser", {
          isDirector: this.isDirector,
          id: this.user.id,
        });
      }
    },
    removeReleatedEstablishment(id) {
      this.releatedEstablishments = this.releatedEstablishments.filter(
        (el) => el.id != id
      );
    },
    openDialog() {
      this.establishmentsSelected = this.releatedEstablishments;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.establishmentsSelected = [];
    },
    closeAndSaveDialog() {
      this.releatedEstablishments = this.establishmentsSelected;
      this.dialog = false;
      this.establishmentsSelected = [];
    },
  },
  computed: {
    isUsernameEmpty() {
      return this.username ? false : true;
    },
    isPasswordEmpty() {
      return this.password ? false : true;
    },
    badNewUser() {
      return this.isUsernameEmpty || this.isPasswordEmpty ? true : false;
    },
    badEditUser() {
      return this.isUsernameEmpty ? true : false;
    },
    relationsToRemove() {
      if (this.removedEstablishmnetsIdsHistory?.length > 0) {
        let userRealtions = this.user.establishmentsRelations;
        return userRealtions
          .filter((el) =>
            this.removedEstablishmnetsIdsHistory?.includes(el.establishmentId)
          )
          .map((el) => el.id);
      } else {
        return [];
      }
    },
    removedEstablishmnetsIdsHistory() {
      if (this.isUserInfo && this.userType == "manager") {
        return this.userEstablishments
          .filter((el) => !this.releatedEstablishmentsIds.includes(el.id))
          .map((el) => el.id);
      } else {
        return [];
      }
    },
    releatedEstablishmentsIds() {
      return this.releatedEstablishments.map((item) => item.id);
    },
    newEstablishmentsRelations() {
      return this.releatedEstablishments
        .filter(
          (el) =>
            !this.userEstablishments.map((item) => item.id).includes(el.id)
        )
        .map((el) => el.id);
    },

    directorSelected() {
      if (this.userType != "director") {
        return false;
      }
      return true;
    },
    colSize() {
      if (this.directorSelected || this.isUserInfo) {
        return 4;
      }
      return 3;
    },
    isReleatedEstablishmentsEmpty() {
      return this?.releatedEstablishments?.length === 0;
    },

    isEstablishmentsEmpty() {
      return this?.filteredEstablishments?.items?.length === 0;
    },
  },
};
</script>
